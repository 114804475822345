import React, { useState, useEffect, useContext, ClipboardEvent } from "react";
import Editor from "@monaco-editor/react";
import { languages } from "../../Constants/languages";
import {
  Compile,
  GetQuestion,
  Submit,
  UserInfo,
  // CompiledStatus,
  // SubmissionStatus,
} from "../../api/api";
import { factorial } from "../../Constants/Questions/factorial";
import { toast } from "react-toastify";
import useLocalStorage from "../../hooks/useLocalStorage";
import Loader from "../Loader/Loader";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext } from "../../App";
import DefaultLoader from "../Loader/DefaultLoader";
import moment from "moment";

interface TestCase {
  _id: string;
  input: string;
  output: string;
}

interface Question {
  _id: string;
  question: string;
  description: string;
  points: number;
  testcases: TestCase[];
  roundNo: number;
  __v: number;
}

const CodeEditor: React.FC = () => {
  const { userDetails, type } = useContext(LayoutContext);
  const params = useParams();
  const questionId = params.questionId;
  const navigate = useNavigate();
  const [user, setUser] = userDetails;
  const [userType, setUserType] = type;
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [userCode, setUserCode] = useLocalStorage("UserCode", {
    language_id: 54,
    language: "cpp",
    source_code: "// Enter your code here",
  });
  const [customInput, setCustomInput] = useState<string>("");
  // const [compileStatus, setCompileStatus] = useState<any>();
  // const [submitStatus, setSubmitStatus] = useState<any>();

  const [output, setOutput] = useState<OutputObj>({ status: "", output: "" });
  const [outputs, setOutputs] = useState<OutputObj[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // const [finalPoints, setFinalPoints] = useLocalStorage("FinalPoints", 0);
  // const [showInstructions, setShowInstructions] = useState<boolean>(true);
  const [question, setQuestion] = useState<Question | null>(null);
  const [start, setStart] = useState<boolean>(false);

  function handleEditorValidation(markers: any) {
    markers.forEach((marker: any) =>
      console.log("onValidate:", marker.message)
    );
  }

  const q = `
  <h1>Cash Change</h1><p>Decrypt the given encrypted piece of code.</p><p><strong>Question:</strong></p><pre class="ql-syntax" spellcheck="false">
  noOfCustomer : Dollar -> takeCustomerOrder();
  bills : Dollar[] -> takeCustomerOrder();
  safe : CashRegister<Dollar, noOfNotes>;
  shopStatus : Open/Closed -> Open;

  process bills {
      given $5 {
      safe.deposit($5);
      }
      given $10 {
        considering safe.lacking($5) {
        shopStatus -> Closed;    	
    }
        safe.withdraw($5);
        safe.deposit($10);
      }
      given $20 {
        considering safe.notLacking($5) & safe.notLacking($10) {
            safe.withdraw($5);
            safe.withdraw($10);
        }
      alternatively considering safe.howMany($5) in excess of 2 {
        safe.withdraw($5);
        safe.withdraw($5);
        safe.withdraw($5);
      }
      alternatively {
        shopStatus -> Closed;
      }
      safe.deposit($20);
      }
  }

  hangBoard(shopStatus);
  </pre><p><strong>Example 1:</strong></p><pre class="ql-syntax" spellcheck="false">Input: [5, 5, 5, 10, 20]
  Output: true
  </pre><p><strong>Example 3:</strong></p><pre class="ql-syntax" spellcheck="false">Input: [5, 5, 10, 10, 20]
  Output: false
  `;

  const preventCopyPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    toast.error("Copying and pasting is not allowed!");
  };

  const checkSubmissionDeadline = (endTime: string) => {
    const startTimeInHour = new Date().getHours();
    const startTimeInMin = new Date().getMinutes();
    const startTimeInSec = new Date().getSeconds();

    const endTimeInHour = new Date(endTime).getHours();
    const endTimeInMin = new Date(endTime).getMinutes();
    const endTimeInSec = new Date(endTime).getSeconds();

    const timeDifferenceInSec =
      endTimeInHour * 3600 +
      endTimeInMin * 60 +
      endTimeInSec -
      (startTimeInHour * 3600 + startTimeInMin * 60 + startTimeInSec);

    if (timeDifferenceInSec === 0) {
      submitCode();
    }
  };

  const fetchUserProfile = async () => {
    try {
      const { data } = await UserInfo();
      localStorage.setItem("user", JSON.stringify(data[0]));
      localStorage.setItem("UserType", "user");
      setUser(data[0]);
      setUserType("user");
      setSubmitting(false);
      navigate("/leaderboard");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  const fetchQuestionById = async (questionId: string) => {
    setLoading(true);
    try {
      const { data } = await GetQuestion(questionId);
      console.log(data);
      setQuestion(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  const compileCode = async (stdinput: string, testCaseNo: number) => {
    setSubmitting(true);
    const userRunCodeData: CodeRunData = {
      language_id: userCode.language_id,
      source_code: btoa(userCode.source_code),
      stdin: btoa(stdinput),
    };
    try {
      const { data } = await Compile(userRunCodeData);
      console.log(data);
      if (data?.status.description === "Accepted") {
        setOutput({ status: "success", output: atob(data?.stdout) });
      } else {
        if (data?.status.description === "Wrong Answer") {
          setOutput({ status: "success", output: atob(data?.stderr) });
        }
        if (data?.status.description === "Compilation Error") {
          setOutput({ status: "error", output: atob(data?.compile_output) });
        }
      }
      setSubmitting(false);
    } catch (error: any) {
      setErrorMessage(error);
      setSubmitting(false);
      toast.error(
        error.response?.data?.error === "No runs left"
          ? "You can't run your code more than 3 times."
          : error.response?.data?.error,
        {
          position: "top-center",
        }
      );
    }
  };

  const submitCode = async () => {
    setSubmitting(true);
    const userSubmitCodeData: CodeSubmitData = {
      roundNo: 1,
      langId: userCode.language_id,
      code: btoa(userCode.source_code),
    };
    try {
      const { data } = await Submit(userSubmitCodeData);
      console.log(data);
      fetchUserProfile();
    } catch (error: any) {
      setSubmitting(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    if (questionId) {
      setStart(true);
      fetchQuestionById(questionId);
      setInterval(() => {
        checkSubmissionDeadline(
          moment(new Date(user?.startTime[0])).add(30, "minutes").format()
        );
      }, 1000);
    }
    // } else {
    //   window.location.reload();
    // }
  }, [questionId]);

  return (
    <div className="user-play-area">
      {loading ? (
        <DefaultLoader loadingState={loading} />
      ) : user?.endTime?.length === 0 ? (
        <>
          <div className="question">
            <h1>{question?.question}</h1>
            {/* <div>{`${parse(`${q}` || "")}`}</div> */}
            <div
              dangerouslySetInnerHTML={{ __html: question?.description || "" }}
            />
          </div>
          <div className="code-editor">
            <div className="editor-code-box">
              <div className="control-section">
                <select
                  name="language"
                  id="language"
                  className="form_input"
                  style={{
                    width: "30%",
                  }}
                  defaultValue={userCode.language}
                  onChange={(e) => {
                    setUserCode({
                      ...userCode,
                      language_id: languages[e.target.selectedIndex].id,
                      language: e.target.value,
                    });
                  }}
                >
                  {languages.map((language: any, index: number) => (
                    <option key={language.id} value={language.label}>
                      {language.value}
                    </option>
                  ))}
                </select>
                <div
                  className="cta-buttons"
                  style={{
                    width: "70%",
                    justifyContent: "flex-end",
                  }}
                >
                  {submitting ? (
                    <Loader loadingState={submitting} />
                  ) : (
                    <>
                      {/* <button
                    className="btn btn-gradient"
                    onClick={() => setShowInstructions(true)}
                  >
                    Instructions
                  </button> */}
                      {/* <button
                        className="btn btn-gradient"
                        onClick={() => setStart(!start)}
                      >
                        {start ? "Stop Blind" : "Start Blind"}
                      </button> */}
                      <button
                        className="btn btn-gradient"
                        onClick={() => {
                          setOutputs([]);
                          if (customInput !== "") {
                            compileCode(customInput, 0);
                          } else {
                            window.alert(
                              "Please enter a custom input before running the code"
                            );
                          }
                        }}
                      >
                        Run Code
                      </button>
                      <button
                        className="btn btn-gradient"
                        onClick={() => {
                          setOutputs([]);
                          submitCode();
                        }}
                      >
                        Submit
                      </button>
                    </>
                  )}
                  {/* <Loader loadingState={true} /> */}
                </div>
              </div>
              <div className="editor-section">
                <div className="editor-wrapper">
                  <Editor
                    height="100%"
                    width="100%"
                    theme="vs-dark"
                    className={`editor ${start ? "blind" : ""}`}
                    language={userCode.language}
                    value={userCode.source_code}
                    onValidate={handleEditorValidation}
                    onChange={(data: any) => {
                      // console.log(data);
                      setUserCode({ ...userCode, source_code: data });
                    }}

                    // onMount={(editor) => {
                    //   editor.onDidChangeCursorPosition(() => {
                    //     editor?.trigger("keyboard", "type", { text: "value" });
                    //     editor?.focus();
                    //     const position: any = editor?.getPosition();
                    //     editor?.setPosition(position);
                    //   });
                    // }}
                  />
                </div>
                <div className="editor-action-box">
                  <div className="user-boxes">
                    <textarea
                      className="user-box input-box"
                      name="input"
                      id="input"
                      placeholder="Enter a single custom input here to run the code"
                      cols={30}
                      rows={10}
                      value={customInput}
                      onChange={(e) => setCustomInput(e.target.value)}
                      onCopy={(e: ClipboardEvent<HTMLTextAreaElement>) =>
                        preventCopyPaste(e)
                      }
                      onPaste={(e: ClipboardEvent<HTMLTextAreaElement>) =>
                        preventCopyPaste(e)
                      }
                      onCut={(e: ClipboardEvent<HTMLTextAreaElement>) =>
                        preventCopyPaste(e)
                      }
                    ></textarea>
                    <div className="user-box output-box" id="output">
                      <pre
                        className={`${
                          output.status === "error" ? "text-red" : "text-green"
                        }`}
                      >
                        {output.output}
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="contest-end-box">
          <p>
            You have successfully submitted your solution in Round 1. Your score
            is{" "}
            <span className="text-neon-blue">
              <strong>{user?.points}</strong>
            </span>
            . Now head to leaderboard to know your rankings.
          </p>
          <button
            className="btn"
            onClick={() => {
              navigate("/leaderboard");
            }}
          >
            Leaderboard
          </button>
        </div>
      )}
      {/* {showInstructions && (
        <div className="instructions">
          <div className="instructions-box">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
              maxime sed aut. At suscipit saepe mollitia tempora a porro fuga
              repellat molestiae praesentium delectus, aliquam cumque debitis
              dicta? Porro, corrupti.
            </p>
            <button
              className="btn"
              onClick={() => {
                setShowInstructions(false);
                if (!start) {
                  setStart(true);
                }
              }}
            >
              Start Coding!
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CodeEditor;
