import type { SVGProps } from "react";

export function ShowPswdIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M245.5 125.6c-.4-.8-8.7-19.3-27.3-37.8S171.4 50 128 50S55 70.5 37.8 87.8s-26.9 37-27.3 37.8a5.7 5.7 0 0 0 0 4.8c.4.8 8.7 19.3 27.3 37.8S84.6 206 128 206s73-20.5 90.2-37.8s26.9-37 27.3-37.8a5.7 5.7 0 0 0 0-4.8ZM128 194c-31.4 0-58.8-11.4-81.4-33.9A133.9 133.9 0 0 1 22.7 128a133.9 133.9 0 0 1 23.9-32.1C69.2 73.4 96.6 62 128 62s58.8 11.4 81.4 33.9a133.9 133.9 0 0 1 23.9 32.1c-6.4 12.2-38.3 66-105.3 66Zm0-112a46 46 0 1 0 46 46a46 46 0 0 0-46-46Zm0 80a34 34 0 1 1 34-34a34.1 34.1 0 0 1-34 34Z"
      ></path>
    </svg>
  );
}
