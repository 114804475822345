import { useState, useEffect } from "react";

// const PREFIX = "codepen-clone-";

function useLocalStorage(key, initialValue) {
  // const prefixedKey = PREFIX + key;
  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(key);
    if (jsonValue !== null) return JSON.parse(jsonValue);

    // if (typeof initialValue === "function") {
    //   return initialValue();
    // } else {
    return initialValue;
    // }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
