import React, { createContext, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "./App.scss";
import userRouteConfig, { adminRouteConfig } from "./routes";

export const LayoutContext = createContext<any>({} as any);

const App: React.FC = () => {
  const location = useLocation();
  const [userDetails, setUserDetails] = useState<any>({});
  const [type, setType] = useState<string>("");

  const userRoutes = useRoutes(userRouteConfig);
  const adminRoutes = useRoutes(adminRouteConfig);

  // useEffect(() => {
  //   if (location.pathname.includes("admin")) {
  //     setType("admin");
  //   } else {
  //     setType("user");
  //   }
  // }, []);
  return (
    <LayoutContext.Provider
      value={{
        userDetails: [userDetails, setUserDetails],
        type: [type, setType],
      }}
    >
      {/* type === "admin" ? adminRoutes : userRoutes */}
      <div className="App">{userRoutes}</div>
    </LayoutContext.Provider>
  );
};

export default App;
