export const languages = [
  {
    id: 54,
    name: "C++ (GCC 9.2.0)",
    value: "C++",
    label: "cpp",
  },
  {
    id: 62,
    name: "Java (OpenJDK 13.0.1)",
    value: "Java",
    label: "java",
  },
  {
    id: 63,
    name: "JavaScript (Node.js 12.14.0)",
    value: "JavaScript",
    label: "js",
  },
  {
    id: 71,
    name: "Python (3.8.1)",
    value: "Python",
    label: "py",
  },
];
