/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "./AdminDashboard.scss";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AddQuestion } from "../../api/api";
import DefaultLoader from "../Loader/DefaultLoader";

const AdminDashboard = () => {
  const [question, setQuestion] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [points, setPoints] = useState<string | null>(null);
  const [testCases, setTestCases] = useState<string | null>(null);
  const [round, setRound] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const emptyFields = () => {
    setTitle(null);
    setPoints(null);
    setQuestion(null);
    setTestCases(null);
    setRound(null);
  };

  const handleQuestionSubmit = async () => {
    if (!question || !title || !points || !testCases || !round) {
      console.log("Please fill all the fields");
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    const questionData = {
      question: title,
      description: question,
      points: parseInt(points as string),
      testcases: JSON.parse(testCases as string),
      roundNo: parseInt(round as string),
    };
    try {
      const { data } = await AddQuestion(questionData);
      emptyFields();
      setLoading(false);
      toast.success("Question added successfully");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    const userType = localStorage.getItem("UserType");
    if (userType !== "admin") {
      navigate("/login");
    }
    const token = localStorage.getItem("UserAuthToken");
    if (token === null && userType === "admin") {
      navigate("/admin/login");
    } else {
      setToken(token);
    }
  }, []);

  return (
    <div className="admin-dashboard-container">
      <fieldset className="admin-question-form">
        <legend>Question Input Form</legend>
        <div className="form-element">
          <label className="title-label" htmlFor="points">
            Question Title
          </label>
          <input
            className="form-input"
            type="text"
            name="title"
            id="title"
            placeholder="Enter the title for the question"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div className="form-element">
          <label className="question-label" htmlFor="question">
            Question Description
          </label>
          <ReactQuill
            theme="snow"
            placeholder="Enter your question's description here"
            onChange={(value) => {
              setQuestion(value);
              console.log(value);
            }}
          />
        </div>
        <div className="form-element">
          <label className="points-label" htmlFor="points">
            Points
          </label>
          <input
            className="form-input"
            type="number"
            name="points"
            id="points"
            placeholder="Enter the points for the question"
            onChange={(e) => {
              setPoints(e.target.value);
            }}
          />
        </div>
        <div className="form-element">
          <label className="round-label" htmlFor="round">
            Round Number
          </label>
          <input
            className="form-input"
            type="number"
            name="round"
            id="round"
            placeholder="Enter round no. for the question"
            onChange={(e) => {
              setRound(e.target.value);
            }}
          />
        </div>
        <div className="form-element">
          <label className="test-cases-label" htmlFor="test-cases">
            Test Cases
          </label>
          <textarea
            className="form-input"
            name="test-cases"
            id="test-cases"
            placeholder="Enter the test cases for the question"
            onChange={(e) => {
              setTestCases(e.target.value);
            }}
          />
        </div>
        {loading ? (
          <DefaultLoader />
        ) : (
          <button
            className="submit-question-button"
            onClick={() => {
              console.log(title);
              console.log(question);
              console.log(points);
              console.log(testCases);
              console.log(round);

              handleQuestionSubmit();
            }}
          >
            Submit Question
          </button>
        )}
      </fieldset>
      <button
        className="btn"
        onClick={() => {
          localStorage.clear();
          navigate("/admin/login");
        }}
        style={{ marginTop: "2rem" }}
      >
        Logout
      </button>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default AdminDashboard;
