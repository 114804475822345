import type { SVGProps } from "react";

export function HidePswdIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M52.4 36a5.9 5.9 0 0 0-8.8 8l20.8 23c-37.2 21.9-53.2 57-53.9 58.6a5.7 5.7 0 0 0 0 4.8c.4.8 8.7 19.3 27.3 37.8S84.6 206 128 206a124.6 124.6 0 0 0 52.6-11.3l23 25.3a5.9 5.9 0 0 0 4.4 2a5.7 5.7 0 0 0 4-1.6a5.8 5.8 0 0 0 .4-8.4Zm48.7 71.3l45 49.5a34 34 0 0 1-45-49.5ZM128 194c-31.4 0-58.8-11.4-81.4-33.9A133.9 133.9 0 0 1 22.7 128c4.3-8.2 20.1-35.2 50-51.9l20.2 22.2a46 46 0 0 0 61.3 67.5l17.8 19.6a113.2 113.2 0 0 1-44 8.6Zm117.5-63.6c-.4 1-10.4 23-32.9 43.2a6 6 0 0 1-4 1.5a6.2 6.2 0 0 1-4.5-2a6 6 0 0 1 .5-8.5a134.2 134.2 0 0 0 28.7-36.6a133.9 133.9 0 0 0-23.9-32.1C186.8 73.4 159.4 62 128 62a116.4 116.4 0 0 0-19.7 1.6a6 6 0 0 1-2-11.8A128.8 128.8 0 0 1 128 50c43.4 0 73 20.5 90.2 37.8s26.9 37 27.3 37.8a5.7 5.7 0 0 1 0 4.8ZM134.4 94.6a6 6 0 0 1 2.2-11.8a46.2 46.2 0 0 1 37.2 40.9a6 6 0 0 1-5.4 6.5h-.6a5.9 5.9 0 0 1-5.9-5.4a34.1 34.1 0 0 0-27.5-30.2Z"
      ></path>
    </svg>
  );
}
