import axios from "axios";

// const API = axios.create({
//   baseURL: process.env.REACT_APP_RAPID_API_URL,
// });

// const ClientAPI = axios.create({
//   baseURL: process.env.REACT_APP_CLIENT_API_URL,
// });

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
});

let authToken = localStorage.getItem("UserAuthToken");

if (authToken) {
  API.defaults.headers.common.Authorization = `Bearer ${authToken}`;
} else {
  delete API.defaults.headers.common.Authorization;
}

export const updateToken = (token: string | null) => {
  if (token) {
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete API.defaults.headers.common.Authorization;
  }
};

const ClientAPIConfig = {
  params: { base64_encoded: "true", fields: "*" },
};

// ADMIN
export const LoginAdmin = (data: AdminLoginData) =>
  API.post("/admin/login", data);

export const AddQuestion = (data: QuestionData) => API.post("/questions", data);

//USER
export const LoginUser = (data: UserLoginData) => API.post("/user/login", data);

export const UserInfo = () => API.get("/user/get");

//QUESTIONS
export const GetQuestion = (questionId: string) =>
  API.get(`/questions?id=${questionId}`);

//LEADERBOARD
export const GetLeaderboard = (roundNo: number) =>
  API.get(`/leaderboard/${roundNo}`);

//ROUND
export const StartRound = (roundNo: number) =>
  API.post("/user/start", { roundNo: roundNo });

//CODE
export const Compile = (userCodeData: CodeRunData) =>
  API.post("/user/run", userCodeData);

export const Submit = (userSubmitCodeData: CodeSubmitData) =>
  API.post("/user/submit", userSubmitCodeData);

// export const Compile = (data: any) =>
//   ClientAPI.post("/submissions", data, ClientAPIConfig);

// export const Compile = (data: any) => API.post("/", data);

// export const CompiledStatus = (token: string) =>
//   ClientAPI.get(`/submissions/${token}`, ClientAPIConfig);

// export const Submit = (data: any) =>
//   ClientAPI.post("/submissions/batch", data, ClientAPIConfig);

// export const SubmissionStatus = (tokens: any) =>
//   ClientAPI.get("/submissions/batch", {
//     params: {
//       tokens: tokens,
//       base64_encoded: "true",
//       fields: "*",
//     },
//   });
