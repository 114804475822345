import React, { useEffect, useRef, useState } from "react";
import "./CountDown.scss";

interface CountDownProps {
  startTime: string;
  endTime: string;
}

const CountDown: React.FC<CountDownProps> = ({ startTime, endTime }) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  const token = localStorage.getItem("UserAuthToken");
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date().toISOString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    // console.log(getTimeRemaining(e));
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setTimer("00:00:00");
    }
  };

  const clearTimer = (
    e: any,
    hours: number,
    minutes: number,
    seconds: number
  ) => {
    // If you adjust it you should also need to
    // adjust the startTime formula we are about
    // to code next
    setTimer(
      (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id: any = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (startTime: string, endTime: string) => {
    const startTimeInHour = new Date(startTime).getHours();
    const startTimeInMin = new Date(startTime).getMinutes();
    const startTimeInSec = new Date(startTime).getSeconds();

    const endTimeInHour = new Date(endTime).getHours();
    const endTimeInMin = new Date(endTime).getMinutes();
    const endTimeInSec = new Date(endTime).getSeconds();

    const hourDifference = endTimeInHour - startTimeInHour;
    const minDifference = endTimeInMin - startTimeInMin;
    const secDifference = endTimeInSec - startTimeInSec;

    const timeDifferenceInSec =
      endTimeInHour * 3600 +
      endTimeInMin * 60 +
      endTimeInSec -
      (startTimeInHour * 3600 + startTimeInMin * 60 + startTimeInSec);

    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + timeDifferenceInSec);
    // console.log(
    //   startTimeInHour,
    //   startTimeInMin,
    //   startTimeInSec,
    //   endTimeInHour,
    //   endTimeInMin,
    //   endTimeInSec,
    //   deadline,
    //   hourDifference,
    //   minDifference,
    //   secDifference
    // );
    return {
      deadline,
      hourDifference,
      minDifference,
      secDifference,
    };
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    if (startTime !== "" && endTime !== "") {
      console.log(startTime, endTime);
      const { deadline, hourDifference, minDifference, secDifference } =
        getDeadTime(startTime, endTime);
      clearTimer(deadline, hourDifference, minDifference, secDifference);
    }
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  //   const onClickReset = () => {
  //     clearTimer(getDeadTime());
  //   };
  return (
    <div
      className="countdown"
      style={{ display: token === null ? "none" : "" }}
    >
      {timer}
    </div>
  );
};

export default CountDown;
