import { useContext, useEffect, useState } from "react";
import "./AdminLogin.scss";
import { ShowPswdIcon } from "../Icons/ShowPswdIcon";
import { HidePswdIcon } from "../Icons/HidePswdIcon";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginAdmin } from "../../api/api";
import DefaultLoader from "../Loader/DefaultLoader";
import { LayoutContext } from "../../App";

const AdminLogin = () => {
  const { userDetails, type } = useContext(LayoutContext);
  const [user, setUser] = userDetails;
  const [userType, setUserType] = type;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async () => {
    setLoading(true);
    const AdminLoginData = {
      userId: email,
      pass: password,
    };
    try {
      const { data } = await LoginAdmin(AdminLoginData);
      console.log(data);
      localStorage.setItem("UserAuthToken", data.token);
      localStorage.setItem("UserType", data.user.userType);
      setUser(data.user);
      setUserType(data.user.userType);
      setEmail("");
      setPassword("");
      setLoading(false);
      toast.success("Admin logged in successfully");
      navigate("/admin/dashboard");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  // useEffect(() => {
  //   setUserType("admin");
  // }, []);

  return (
    <div className="admin-main-container">
      <fieldset className="admin-login-form">
        <legend>Admin Login</legend>
        <div className="form-element">
          <label className="email-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-input"
            type="text"
            name="email"
            id="email"
            placeholder="Enter your email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-element">
          <label className="pswd-label" htmlFor="password">
            Password
          </label>
          <div className="pswd-input-container">
            <input
              className="form-input pswd-input"
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter your password"
              id="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button
              className="pswd-btn"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <HidePswdIcon /> : <ShowPswdIcon />}
            </button>
          </div>
        </div>
        {loading ? (
          <DefaultLoader />
        ) : (
          <button className="btn" onClick={() => handleLogin()}>
            Login
          </button>
        )}
      </fieldset>
    </div>
  );
};

export default AdminLogin;
