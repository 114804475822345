import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StartRound, UserInfo } from "../../api/api";
import { LayoutContext } from "../../App";
import CountDown from "../../components/CountDown/CountDown";
import DefaultLoader from "../../components/Loader/DefaultLoader";
import "./Instructions.scss";

const Instructions: React.FC = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(LayoutContext);
  const [user, setUser] = userDetails;
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserProfile = async (questionId: string) => {
    try {
      const { data } = await UserInfo();
      localStorage.setItem("user", JSON.stringify(data[0]));
      localStorage.setItem("UserType", "user");
      setUser(data[0]);
      setLoading(false);
      navigate(`/contest/${questionId}`);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  const startContest = async (roundNo: number) => {
    setLoading(true);
    try {
      const { data } = await StartRound(roundNo);
      // console.log(data[0]);
      fetchUserProfile(data._id);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };
  return (
    <div className="contest-info-container container">
      <div className="contest-info-wrapper wrapper">
        <h2>RULES AND INSTRUCTIONS</h2>
        <div className="rules">
          <h4 className="info-title">Rules:</h4>
          <ul className="info-list">
            <li>Participants must bring their own laptops.</li>
            <li>
              Participants must not engage in any activity that could harm the
              competition infrastructure or other participants.{" "}
            </li>
            <li>
              Participants must adhere to the Blind Codings’s code of conduct.{" "}
            </li>
            <li>
              There will be only one round and the top 3 on the leaderboard will
              be the winner.
            </li>
            <li>
              Trying to solve the problem by any unfair means such as copy
              pasting the code, switching tabs etc. will lead to
              disqualification.
            </li>
          </ul>
        </div>
        <div className="instructions">
          <h4 className="info-title">Instructions:</h4>
          <ol className="info-list">
            <li>
              Start Round: You can start the event only from 10 AM and must
              login with your Espektro ID and the Ticket Number that is on the
              Ticket that you got after registering for the event.
            </li>
            <li>
              Duration: The event will take place from 10 AM and will continue
              upto 12:30 PM and you will get 1 hour max from since you started
              to solve the problem and submit the answer.
            </li>
            <li>
              Problem statement: You will be given a problem statement to solve.
              You will have to write the code for the given problem statement in
              the text box provided by the platform. The text box provided by
              the platform will disappear and rotate at certain time periods.
            </li>
            <li>
              Scoring: The code written by you will be evaluated based on the
              correctness and efficiency of the solution. Points will be awarded
              based on the accuracy of the output and the time taken to complete
              the code.
            </li>
            <li>
              Winner: The winners will be announced based on the rankings on the
              leaderboard.
            </li>
          </ol>
        </div>
        <div className="countdown-info">
          <h2>Time remaining to start the contest:</h2>
          <CountDown
            startTime={moment(new Date()).format()}
            endTime={moment(new Date("2023-03-24T10:00:00+05:30")).format()}
          />
        </div>
        {loading ? (
          <DefaultLoader />
        ) : (
          <button
            className="btn"
            onClick={() => {
              startContest(1);
            }}
          >
            Start Coding!
          </button>
        )}
      </div>
    </div>
  );
};

export default Instructions;
