import React, { CSSProperties } from "react";
import {
  PropagateLoader,
  BounceLoader,
  BeatLoader,
  PulseLoader,
} from "react-spinners";

export const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
};

const Loader: React.FC<any> = ({ loadingState }) => {
  return (
    // <div
    //   className="bounce-loader-container"
    //   style={{
    //     minHeight: "200px",
    //     display: "grid",
    //     placeContent: "center",
    //   }}
    // >
    <div className="bounce-loader" style={{ marginInline: "auto" }}>
      <PulseLoader
        size={12}
        color="#00b0e0"
        loading={loadingState}
        cssOverride={override}
        speedMultiplier={0.5}
      />
    </div>
    // </div>
  );
};

export default Loader;
