import { useContext, useEffect, useState } from "react";
import "./UserLogin.scss";
import { ShowPswdIcon } from "../Icons/ShowPswdIcon";
import { HidePswdIcon } from "../Icons/HidePswdIcon";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginUser, updateToken, UserInfo } from "../../api/api";
import { LayoutContext } from "../../App";
import DefaultLoader from "../Loader/DefaultLoader";
import CountDown from "../CountDown/CountDown";
import moment from "moment";

const UserLogin = () => {
  const { userDetails, type } = useContext(LayoutContext);
  const [user, setUser] = userDetails;
  const [userType, setUserType] = type;

  const [loading, setLoading] = useState<boolean>(false);

  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    try {
      const { data } = await UserInfo();
      localStorage.setItem("user", JSON.stringify(data[0]));
      localStorage.setItem("UserType", "user");
      setUser(data[0]);
      setUserType("user");
      setUserID("");
      setPassword("");
      setLoading(false);
      setTimeout(() => {
        navigate("/instructions");
      }, 1000);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  const handleLogin = async () => {
    if (userID === "") {
      window.alert("Please enter your Espektro ID");
      return;
    }
    if (password === "") {
      window.alert("Please enter your Event Ticket No");
      return;
    }
    setLoading(true);
    const userLoginData = {
      userId: userID,
      password: password,
    };
    try {
      const { data } = await LoginUser(userLoginData);
      localStorage.setItem("UserAuthToken", data.token);
      updateToken(data.token);
      fetchUserProfile();
      toast.success(
        "Welcome to Blind Coding. Read instructions properly and jump into coding.",
        { position: "top-center", autoClose: 10000 }
      );
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("UserAuthToken");
    if (token !== null) {
      navigate("/instructions");
    }
  }, []);

  return (
    <div className="user-main-container">
      <fieldset className="user-login-form">
        <legend>User Login</legend>
        <div className="form-element">
          <label className="id-label" htmlFor="espektroId">
            Espektro ID
          </label>
          <input
            className="form_input"
            type="text"
            name="espektroId"
            id="espektroId"
            placeholder="Enter your Espektro ID"
            onChange={(e) => {
              setUserID(e.target.value);
            }}
          />
        </div>
        {/* <CountDown
          startTime={moment(new Date()).format()}
          endTime={moment().add(1, "hours").format()}
        /> */}
        <div className="form-element">
          <label className="pswd-label" htmlFor="ticketNo">
            Ticket No
          </label>
          <div className="pswd-input-container">
            <input
              className="form_input pswd-input"
              type={showPassword ? "text" : "password"}
              name="ticketNo"
              placeholder="Enter your Ticket No"
              id="ticketNo"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button
              className="pswd-btn"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <HidePswdIcon /> : <ShowPswdIcon />}
            </button>
          </div>
        </div>
        {loading ? (
          <DefaultLoader loadingState={loading} />
        ) : (
          <button className="btn" onClick={() => handleLogin()}>
            Login
          </button>
        )}
      </fieldset>
    </div>
  );
};

export default UserLogin;
