import { Navigate, RouteObject } from "react-router-dom";
import EditorPage from "./containers/EditorPage/EditorPage";
import Layout from "./containers/Layout/Layout";
import AdminLogin from "./components/Admin/AdminLogin";
import AdminDashboard from "./components/Admin/AdminDashboard";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import UserLogin from "./components/User/UserLogin";
import Instructions from "./containers/Instructions/Instructions";

export const adminRouteConfig: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <EditorPage />,
      },
      {
        path: "/admin/login",
        element: <AdminLogin />,
      },
      {
        path: "/admin/dashboard",
        element: <AdminDashboard />,
      },
    ],
  },
];

const userRouteConfig: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={"/instructions"} />,
      },
      {
        path: "/login",
        element: <UserLogin />,
      },
      {
        path: "/instructions",
        element: <Instructions />,
      },
      {
        path: "/contest/:questionId",
        element: <EditorPage />,
      },
      {
        path: "/leaderboard",
        element: <Leaderboard />,
      },
      {
        path: "/admin/login",
        element: <AdminLogin />,
      },
      {
        path: "/admin/dashboard",
        element: <AdminDashboard />,
      },
    ],
  },
];

export default userRouteConfig;
