import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { updateToken, UserInfo } from "../../api/api";
import { LayoutContext } from "../../App";
import GDSCLoader from "../../components/Loader/GDSCLoader/GDSCLoader";
import Navbar from "../../components/Navbar/Navbar";

const Layout: React.FC = () => {
  const { userDetails, type } = useContext(LayoutContext);
  const [user, setUser] = userDetails;
  const [userType, setUserType] = type;
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("UserAuthToken");
  const UserRole = localStorage.getItem("UserType");

  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const { data } = await UserInfo();
      console.log(data);
      setUser(data[0]);
      setUserType("user");
      setTimeout(() => setLoading(false), 3000);
    } catch (error: any) {
      setLoading(false);
      toast.error(error, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    updateToken(token);
    if (token === null) {
      if (
        location.pathname.includes("/admin") ||
        (location.pathname === "/leaderboard") === true
      ) {
        return;
      } else {
        navigate("/login");
      }
    }
    if (token !== null && UserRole === "user") {
      fetchUserProfile();
    }
  }, []);
  return (
    <div className="layout-container">
      <div className="contents">
        {loading ? <GDSCLoader /> : null}
        {token !== null && userType === "user" ? <Navbar /> : null}
        <Outlet />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Layout;
