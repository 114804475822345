import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetLeaderboard } from "../../api/api";
import { LayoutContext } from "../../App";
import DefaultLoader from "../Loader/DefaultLoader";
import "./Leaderboard.scss";

type leaderboardMember = {
  name: string;
  rank: number;
  points: number;
  time_taken: number;
};

const Leaderboard = () => {
  const { userDetails, type } = useContext(LayoutContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = userDetails;
  const [userType, setUserType] = type;
  const [userRank, setUserRank] = useState<number>();
  const [leaderboard, setLeaderboard] = useState<any>([]);

  const token = localStorage.getItem("UserAuthToken");

  const getSubmissionTime = (startTime: number, endTime: number) => {
    // console.log(startTime, new Date(startTime), endTime, new Date(endTime));
    const startTimeInMin = new Date(startTime).getMinutes();
    const startTimeInSec = new Date(startTime).getSeconds();
    const startTimeInMiliSec = new Date(startTime).getMilliseconds();

    const endTimeInMin = new Date(endTime).getMinutes();
    const endTimeInSec = new Date(endTime).getSeconds();
    const endTimeInMiliSec = new Date(endTime).getMilliseconds();

    const timeTaken =
      endTimeInMin +
      endTimeInSec / 60 +
      endTimeInMiliSec / 60000 -
      (startTimeInMin + startTimeInSec / 60 + startTimeInMiliSec / 60000);

    return timeTaken;
  };

  const continuousLeaderboard = async () => {
    const roundNo = 1;
    try {
      const { data } = await GetLeaderboard(roundNo);
      console.log(data);
      const users = data;
      users?.forEach((userItem: any, index: number) => {
        if (user?._id === userItem?._id) {
          setUserRank(index + 1);
        }
      });
      setLeaderboard(data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  const fetchLeaderboard = async () => {
    setLoading(false);
    const roundNo = 1;
    try {
      const { data } = await GetLeaderboard(roundNo);
      console.log(data);
      const users = data;
      users?.forEach((userItem: any, index: number) => {
        if (user?._id === userItem?._id) {
          setUserRank(index + 1);
        }
      });
      setLeaderboard(data);
      setInterval(() => {
        continuousLeaderboard();
      }, 3000);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response?.data?.error, {
        position: "top-center",
      });
    }
  };

  const getTopThree = () => {
    // const sortedLeaderboard = leaderboard.sort((a, b) => {
    //   if (a.points === b.points) {
    //     return a.time_taken - b.time_taken;
    //   }
    //   return b.points - a.points;
    // });

    return [leaderboard[0], leaderboard[1], leaderboard[2]];
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    fetchLeaderboard();
  }, []);

  return (
    <div className="leaderboard-container">
      {loading ? (
        <DefaultLoader loadingState={loading} />
      ) : (
        <>
          <div className="leaderboard-header">
            <h1>Blind Coding Leaderboard</h1>
          </div>

          {leaderboard?.length >= 3 ? (
            <div className="top-three">
              {getTopThree().map((member, index) => (
                <div className="top-three-member">
                  <span>#{index + 1}</span>
                  <h2>{member?.name}</h2>
                  <p>
                    {member?.points} points |{" "}
                    {getSubmissionTime(
                      member?.startTime[0],
                      member?.endTime[0]
                    ).toFixed(2)}{" "}
                    min.
                  </p>
                </div>
              ))}
            </div>
          ) : null}
          {token !== null && userType === "user" ? (
            <div className="user-ranking">
              <div className="ranking-info">
                <span className="label">Your Rank:</span>
                <span className="text">{userRank}</span>
              </div>
              <div className="ranking-info">
                <span className="label">Your Points:</span>
                <span className="text">{user?.points}</span>
              </div>
              <div className="ranking-info">
                <span className="label">Time Taken:</span>
                <span className="text">
                  {getSubmissionTime(
                    user?.startTime[0],
                    user?.endTime[0]
                  ).toFixed(2)}{" "}
                  min.
                </span>
              </div>
            </div>
          ) : null}
          <div className="leaderboard">
            <table>
              <thead>
                <tr>
                  <th>Participant</th>
                  <th>Rank</th>
                  <th>Points</th>
                  <th>Time Taken</th>
                </tr>
              </thead>
              <tbody>
                {leaderboard.map((member: any, index: number) => (
                  <tr>
                    <td>{member?.name}</td>
                    <td>{index + 1}</td>
                    <td>{member?.points}</td>
                    <td>
                      {getSubmissionTime(
                        member?.startTime[0],
                        member?.endTime[0]
                      ).toFixed(2)}{" "}
                      min.
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Leaderboard;
