/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import CodeEditor from "../../components/Editor/CodeEditor";
import "./EditorPage.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EditorPage: React.FC = () => {
  // const [userToken, setUserToken] = useState<string | null>(null);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const userToken = localStorage.getItem("user-token");

  //   if (!userToken) {
  //     navigate("/login");
  //   } else {
  //     setUserToken(userToken);
  //   }
  // }, []);

  return (
    <div className="editor-page-container container">
      <div className="editor-page-wrapper wrapper">
        <CodeEditor />
      </div>
    </div>
  );
};

export default EditorPage;
